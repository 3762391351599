import React, { useState, useEffect } from 'react';
import './Slideshow.css'; // Import the CSS for the slideshow
import burger from './../assets/burger.png';
import fajitaPlate from './../assets/fajita-plate.png';
import shrimpSoup from './../assets/shrimp-soup.png';


const slides = [
  burger,
  fajitaPlate,
  shrimpSoup
];

const Slideshow = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
    }, 5000); // Change slide every 5 seconds

    return () => clearInterval(intervalId); // Clean up interval on component unmount
  }, []);

  return (
    <div className="slideshow">
      {slides.map((slide, index) => (
        <img
          key={index}
          src={slide}
          alt={`Slide ${index + 1}`}
          className={`slide ${index === currentIndex ? 'active' : ''}`}
        />
      ))}
    </div>
  );
};

export default Slideshow;
