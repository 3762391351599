import React from 'react';
import './Location.css'

const text = {
  Eng: {
    title: 'Location',
    address: 'Find us at 10400 US-290, Manor TX, 78653.',
    hours: 'Operating Hours:',
    schedule: [
      'Monday: 6:30 AM – 3 PM',
      'Tuesday: 6:30 AM – 3 PM',
      'Wednesday: 6:30 AM – 3 PM',
      'Thursday: 6:30 AM – 8 PM',
      'Friday: 6:30 AM – 8 PM',
      'Saturday: 6:30 AM – 8 PM',
      'Sunday: 8:30 AM – 1 PM'
    ]
  },
  Esp: {
    title: 'Ubicación',
    address: 'Encuéntranos en 10400 US-290, Manor TX, 78653.',
    hours: 'Horario de Atención:',
    schedule: [
      'Lunes: 6:30 AM – 3 PM',
      'Martes: 6:30 AM – 3 PM',
      'Miércoles: 6:30 AM – 3 PM',
      'Jueves: 6:30 AM – 8 PM',
      'Viernes: 6:30 AM – 8 PM',
      'Sábado: 6:30 AM – 8 PM',
      'Domingo: 8:30 AM – 1 PM'
    ]
  }
};

const Location = ({ language }) => {
  const locationText = text[language] || text.Eng;

  return (
    <section id="location" className="location">
        <div>
        <h2>{locationText.title}</h2>
            <p>{locationText.address}</p>
            <p>{locationText.hours}</p>
            <ul>
                {locationText.schedule.map((time, index) => (
                    <li key={index}>{time}</li>
                ))}
            </ul>
        </div>
        <div className="map-container">
        <iframe
            title="maps location"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1256.2655187613598!2d-97.5616118883082!3d30.34818910923616!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8644b7a4eb14ccfb%3A0x59564359d4e519b!2sLa%20Parrillita!5e0!3m2!1sen!2sus!4v1725898457518!5m2!1sen!2sus"
            width="600"
            height="450"
            style={{ border: 0, borderRadius: 10}}
            loading="lazy">
        </iframe>
      </div>

    </section>
  );
};

export default Location;
